import PropTypes from "prop-types";
import React from "react";
import ReactPlayer from "react-player";

export const PropertyVideo = ({ property }) => {
  const youtubeUrl = property?.videos?.video?.link?.rel == "YOUTUBE"
    ? property?.videos?.video?.link?.href
    : "";
  return youtubeUrl ? (
    <div className="video-wrapper">
      <ReactPlayer width="100%" height="100%" className="property-video" url={youtubeUrl} controls={true} />
    </div>
  ) : null;
};
PropertyVideo.propTypes = {
  property: PropTypes.shape({
    videos: PropTypes.shape({
      video: PropTypes.shape({
        link: PropTypes.shape({
          href: PropTypes.string,
          rel: PropTypes.string
        })
      })
    })
  })
};
