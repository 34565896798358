import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"
import { GlobalStateContext } from "../context/GlobalContextProvider"
import { Swiper } from "swiper/bundle"
import { adHelper } from "../lib/ad_helper"

export const PicturesSlider = ({ property }) => {
  const state = useContext(GlobalStateContext)
  useEffect(() => {
    if (state.isReady && typeof window !== "undefined") {
      // Swiper.init();
      // if (typeof window !== 'undefined') initSwiper()
      var galleryThumbs = new Swiper(".gallery-thumbs", {
        spaceBetween: 10,
        slidesPerView: 4,
        loop: false,
        freeMode: true,
        loopedSlides: 5,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        breakpoints: {
          280: {
            slidesPerView: 0,
          },
          640: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 3,
          },
        },
      })
      // eslint-disable-next-line no-unused-vars
      const pictureSwiper = new Swiper(".gallery-top", {
        spaceBetween: 10,
        loop: false,
        effect: "fade",
        loopedSlides: 5,
        navigation: {
          nextEl: ".single-prop-swiper-nav-next",
          prevEl: ".single-prop-swiper-nav-prev",
        },
        thumbs: {
          swiper: galleryThumbs,
        },
      })
      return () => {
        pictureSwiper.destroy(true, true);
        galleryThumbs.destroy(true, true);
      }
    }
  }, [state.isReady])
  return (
    <div className="section clearfix properties-slider">
      <div className="container clearfix">
        <div
          className="swiper-container gallery-top"
          data-aos="fade-in"
          data-aos-delay="300"
          data-aos-duration="500"
        >
          <div
            className="swiper-wrapper"
          >
            {adHelper.largePictureUrls(property).map(url => (
              <PropertyPicture key={url} url={url} />
            ))}
          </div>
          <div className="single-prop-swiper-nav-holder clearfix position-absolute bg-green-3">
            <a className="single-prop-swiper-nav-prev">
              <img src="/images/long-arrow-left-white.png" alt="prev" />{" "}
              Previous
            </a>
            <a className="single-prop-swiper-nav-next">
              Next <img src="/images/long-arrow-right-white.png" alt="next" />
            </a>
          </div>
        </div>
        <div
          className="swiper-container gallery-thumbs"
          data-aos="fade-in"
          data-aos-duration="450"
        >
          <div
            className="swiper-wrapper"
          >
            {adHelper.thumbPictureUrls(property).map(url => (
              <PropertyPicture key={url} url={url} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
PicturesSlider.propTypes = {
  property: PropTypes.any,
}

const PropertyPicture = ({ url }) => (
  <div
    className="swiper-slide"
    style={{
      backgroundImage: `url(${url})`,
    }}
  ></div>
)

PropertyPicture.propTypes = {
  url: PropTypes.string,
}
