import PropTypes from "prop-types"
import React from "react"
import { accounting } from "accounting"
import { adHelper } from "../lib/ad_helper"
// import { SocialShare } from "./SocialShare"

export const PropertyDetails = ({ property }) => (
  <div className="section clearfix prop-details-holder my-md-5 py-5">
    <div className="container clearfix">
      <div className="prop-details-first-row clearfix row mb-5">
        <div
          className="col-sm-9 clearfix prop-details-first-row-col prop-details-first-row-col-left"
          data-aos="fade-in"
          data-aos-duration="450"
        >
          <h5 className="text-green-3 font-TitilliumWeb-Bold font-26 mb-2">
            <span className="font-32">R</span>{" "}
            {accounting.formatMoney(Number(property?.price?.amount), "")}
          </h5>
          <h1 className="font-raleway-bold font-48 text-black">
            {property?.title}
          </h1>
        </div>
        <div className="col-sm-3 clearfix prop-details-first-row-col prop-details-first-row-col-right text-right">
          {/* <a
            className="social-link-1 edge-bottom-right rounded-circle bg-green-3 text-white font-32 d-inline-flex align-items-center justify-content-center text-decoration-none mr-1"
            data-aos="zoom-in-up"
            data-aos-duration="450"
            data-aos-delay="100"
          >
            <i className="far fa-heart"></i>
          </a> */}

          {/* <SocialShare /> */}

        </div>
      </div>
      <p
        className="font-AvenirLTStd-Roman font-26 text-black opacity-0-7 line-height-2"
        data-aos="fade-in"
        data-aos-duration="450"
        dangerouslySetInnerHTML={{
          __html: adHelper.toHtml(property?.description),
        }}
      ></p>
    </div>
  </div>
)

PropertyDetails.propTypes = {
  property: PropTypes.shape({
    description: PropTypes.string,
    locations: PropTypes.shape({
      location: PropTypes.shape({
        localizedName: PropTypes.string
      })
    }),
    price: PropTypes.shape({
      amount: PropTypes.number
    }),
    title: PropTypes.string
  })
}


