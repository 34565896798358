import PropTypes from "prop-types"
import React from "react"
import { LetsTalkCard } from "./LetsTalkCard"

export const PropertyBanner = ({
  bannerImg = "/images/000000.png",
  subtitle = "Want to get the best",
  title = "Browse Properties",
  agentEmail,
  agentPhone,
  talkMessage = '',
}) => (
  <div className="inner-page-header clearfix position-relative">
    <img
      src={bannerImg}
      alt="classic realtors"
      className="object-fit-cover w-100 h-100"
    />
    <div className="inner-page-header-caption-holder clearfix position-absolute">
      <div className="container clearfix">
        <div className="row clearfix">
          <div
            className="col-md-6 clearfix inner-page-header-caption-col clearfix inner-page-header-caption-col-left pb-5 mb-5"
            data-aos="fade-in"
            data-aos-delay="450"
          >
            <p className="custom-carousel-caption-subtitle text-uppercase mb-3 text-white">
              {subtitle}
            </p>
            <h3 className="custom-carousel-caption-title-1 text-white mb-4 font-65">
              {title}
            </h3>
            <nav className="breadcrumb rounded-0 bg-transparent align-items-center px-0">
              <a
                className="breadcrumb-item text-uppercase text-white font-TitilliumWeb-SemiBold"
                href="/"
              >
                Home
              </a>
              <a
                href="/properties"
                className="breadcrumb-item active text-uppercase text-green-1 font-TitilliumWeb-SemiBold"
              >
                Properties
              </a>
              <img
                src="/images/long-arrow-right-white.png"
                className="ml-2 w-auto"
                alt="right"
              />
            </nav>
          </div>
          <LetsTalkCard phone={agentPhone} email={agentEmail} message={talkMessage} />
        </div>
      </div>
    </div>
  </div>
)

PropertyBanner.propTypes = {
  agentEmail: PropTypes.string,
  agentPhone: PropTypes.any,
  bannerImg: PropTypes.string,
  subtitle: PropTypes.string,
  talkMessage: PropTypes.string,
  title: PropTypes.string
}


