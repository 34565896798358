import PropTypes from "prop-types";
import React from "react"
import { adHelper } from '../lib/ad_helper';

export const AgentDetails = ({property}) => {
  const agent = adHelper.parseAgent(property?.description)
  return (
    <div className="section clearfix prop-dtls-pg-agnt-dtl-section bg-black">
      <div className="container clearfix">
        <div className="row clearfix align-items-center">
          <div
            className="col-md-6 clearfix prop-dtls-pg-agnt-dtl-section-col prop-dtls-pg-agnt-dtl-section-col-1 order-md-last"
            data-aos="fade-in"
            data-aos-delay="450"
          >
            <div className="prop-dtls-pg-agnt-dtls-holder clearfix d-flex bg-black-2 p-3 mb-md-5">
              <div className="prop-dtls-pg-agnt-dtls-img-holder clearfix">
                <img
                  src={`/images/agents/large/${agent.name}.jpg`}
                  alt="agent"
                  className="w-100"
                />
              </div>
              <div className="prop-dtls-pg-agnt-dtls-cntnt-holder clearfix d-flex pt-2 flex-wrap">
                <div className="prop-dtls-pg-agnt-dtls-cntnt-holder-col col-lg-5 col-sm-12 pr-0">
                  <h2 className="font-26 font-TitilliumWeb-Bold text-white mb-1">
                    {agent.name}
                  </h2>
                  <p className="font-19 font-AvenirLTStd-Medium text-white">
                    {agent.role}
                  </p>
                </div>
                <div className="prop-dtls-pg-agnt-dtls-cntnt-holder-col col-lg-7 col-sm-12 pr-0">
                  <a href={adHelper.phoneLink(agent.phone)} className="text-decoration-none text-white font-AvenirLTStd-Medium font-18 mb-2 w-100 d-flex align-items-center">
                    <i className="fas fa-mobile mr-2 font-23"></i>
                    <span>{agent.phone}</span>
                  </a>
                  <a
                    href={`mailto:${agent.userEmail}?subject=About ${property?.title} (${adHelper.parseRef(property?.description)})`}
                    className="text-decoration-none text-white font-AvenirLTStd-Medium font-18 mb-2 w-100 d-flex align-items-center"
                  >
                    <i className="fas fa-envelope-open mr-2"></i>
                    <span>{agent.userEmail}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 clearfix prop-dtls-pg-agnt-dtl-section-col prop-dtls-pg-agnt-dtl-section-col-2 order-md-first icon-bounce-animation">
            <a
              onClick={() => history.back()}
              data-toggle="tooltip"
              data-placement="right"
              title="Back"
            >
              <img
                src="/images/long-arrow-left-white.png"
                alt="back"
                title="Back"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

AgentDetails.propTypes = {
  property: PropTypes.shape({
    description: PropTypes.string,
    title: PropTypes.string
  })
}
