import PropTypes from "prop-types"
import React from "react"
import { accounting } from "accounting"
import { Layout } from "../../components/Layout"
import { AgentDetails } from "../../components/AgentDetails"
import { PicturesSlider } from "../../components/PicturesSlider"
import { PropertyBanner } from "../../components/PropertyBanner"
import { PropertyDetails } from "../../components/PropertyDetails"
import { adHelper } from "../../lib/ad_helper"
// import { NearbyLocation } from "../../components/NearbyLocation"
// import { PropertyExtraPictures } from "../../components/PropertyExtraPictures"
// import { PropertyFeatures } from "../../components/PropertyFeatures"
// import { PropertyAmenities } from "../../components/PropertyAmenities"
// import { RelatedProperties } from "../../components/RelatedProperties"
// import { LogoSlider } from '../../components/LogoSlider';
import { PropertyVideo } from "../../components/PropertyVideo"

export default function PropertyPage({ id, pageContext: { propertyId } = {} }) {
  const property = adHelper.adById(id || propertyId)
  const agent = adHelper.parseAgent(property?.description);
  return (
    <Layout className="prop-details-page">
      <PropertyBanner
        title={accounting.formatMoney(Number(property?.price?.amount), "R")}
        subtitle={property?.locations?.location?.localizedName}
        bannerImg={adHelper.firstLargePictureUrl(property)}
        agentPhone={agent?.phone}
        agentEmail={agent?.userEmail}
        talkMessage={`About ${property?.title} (${adHelper.parseRef(property?.description)}), `}
      />
      <AgentDetails property={property} />
      <PropertyVideo property={property} />
      {adHelper.largePictureUrls(property).length > 1 ? (
        <PicturesSlider property={property} />
      ) : (
        <div></div>
      )}
      <PropertyDetails property={property} />
      {/* <PropertyFeatures /> */}
      {/* <PropertyAmenities /> */}
      {/* <PropertyExtraPictures /> */}
      {/* <NearbyLocation /> */}
      {/* <RelatedProperties /> */}
      {/* <LogoSlider /> */}
    </Layout>
  )
}

PropertyPage.propTypes = {
  id: PropTypes.string,
  pageContext: PropTypes.shape({
    propertyId: PropTypes.string
  })
}