import PropTypes from "prop-types"
import React from "react"
import { adHelper } from '../lib/ad_helper';

export const LetsTalkCard = ({ email, phone = '', message = '' }) => (
  <div className="col-md-6 clearfix inner-page-header-caption-col clearfix inner-page-header-caption-col-left align-items-end d-flex">
    <a
      href={phone ? adHelper.whatsappLink(phone, message) : `mailto:${email}?subject=${encodeURIComponent(message)}`}
      className="lets-talk-holder clearfix bg-green-1 row p-4 mb-0 text-decoration-none"
      data-aos="fade-up"
      data-aos-delay="450"
    >
      <div className="col-md-7 clearfix lets-talk-holder-col lets-talk-holder-col-left">
        <h3 className="text-uppercase text-white font-22 font-Brandon_med mb-3">
          Let’s Talk
        </h3>
        <p className="font-18 font-Brandon_med text-white opacity-0-7 letter-space-1">
          We may have the perfect home or investment for you.
        </p>
      </div>
      <div className="col-md-5 clearfix lets-talk-holder-col lets-talk-holder-col-right justify-content-end align-items-end d-flex">
        <img
          src="/images/long-arrow-right-white.png"
          className="ml-2 w-auto mb-3"
          alt="right"
        />
      </div>
    </a>
  </div>
)
LetsTalkCard.propTypes = {
  email: PropTypes.string,
  message: PropTypes.string,
  phone: PropTypes.string
}
